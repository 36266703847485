<template>
  <div class="payment-finish-page">
    <div class="payment-finish">
      <img src="@/assets/images/icons/ic_checkmark_circle.svg" class="payment-finish-img" alt="circle">
      <div class="payment-finish-notify mt-3">
        <p class="payment-finish-title">Congratulations, you have successfully completed the pre-approval with AutoFlip.</p>
        <p class="payment-finish-content font-weight-thin mb-4">Your payment method is successfully verified, ensuring a smooth and hassle-free experience as you proceed with your sale.</p>
      </div>
      <div class="payment-finish-instruction">
        <p class="payment-finish-text mb-0">Next Steps:</p>
        <ol class="payment-finish-steps" type="1">
          <li>Receive Invoice: <span class="font-weight-thin">Within the next 24 hours, you will receive an invoice confirming the pre-authorization of your success fee. This invoice will detail the amount pre-authorised and provide further information regarding the payment process.</span></li>
          <li>Payment Deduction: <span class="font-weight-thin">Your payment will be deducted in full after 7 days from the pre-authorisation date. This timeframe allows you ample time to complete the necessary steps for your sale, including handing over your car and receiving payment from your buyer.</span></li>
          <li>
            Stay Updated: <span class="font-weight-thin">We will keep you updated throughout the process to ensure transparency and clarity. 
            If you have any questions or require assistance at any point, feel free to reach out to our dedicated support team at</span>
            <br>
            <a :href="`mailto:${contactDetails.email}`" class="font-weight-thin"><u>{{ contactDetails.email }}</u></a> <span class="font-weight-thin">or </span>
            <a :href="`tel:${contactDetails.phoneNumber}`" class="font-weight-thin"><u>{{ contactDetails.phoneNumber }}</u></a>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  CLEAR_ALL_PAYMENT_INFO,
} from "@/store/payment/actions";
export default {
  name: "Finish",
  computed: {
    ...mapState({
      contactDetails: (state) => state.commonVariables.contactDetails,
    }),
  },
  mounted () {
    this.$store.dispatch(CLEAR_ALL_PAYMENT_INFO);
  },
};
</script>

<style lang="scss">
.container {
  max-width: 100vw;
  @media screen and (min-width: 768px) {
    padding: 0 !important;
  }
}
.payment-finish-page {
  background-color: #F9FAFB;
  min-height: 92vh;
  overflow: hidden;
  .payment-finish {
    padding: 0 50px;
    margin-left: auto;
    margin-right: auto;
margin-top: 45px;
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
    .payment-finish-img {
      display: block;
      width: 68px;
      height: 68px;
      margin-left: auto;
      margin-right: auto;
    }
    .payment-finish-notify {
      text-align: center;
      .payment-finish-title {
        font-size: 20px;
        color: var(--primary-color);
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }
      .payment-finish-content { 
        font-size: 16px; 
      }
    }
    .payment-finish-instruction {
      font-size: 16px;
      .payment-finish-steps {
        padding: 0 0 0 17px !important;
      }
      .payment-finish-text {
        color: var(--primary-color);
      }
      .payment-finish-steps {
        a {
          color: #000000;
        }
      } 
    }
  }
}

@media screen and(min-width: 768px) {
  .payment-finish {
    .payment-finish-notify {
      text-align: left !important;
      .payment-finish-title {
        font-size: 22px !important;
      }
      .payment-finish-content {
        font-size: 17px !important;
      }
    }
    .payment-finish-instruction {
      font-size: 17px !important;
    }
  }
}
@media screen and(min-width: 991px) {
  .payment-finish {
    padding: 0 200px !important;
  }
}
</style>